import Matter from 'matter-js'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { randomNumberGenerator } from '@kaliber/math'

function initBallsAnimation(containerElm, images) {
  const elmWidth = containerElm.offsetWidth
  const elmHeight = containerElm.offsetHeight
  const ballDiameter = Math.min(elmWidth / 5, 130)
  const ballR = ballDiameter / 2
  const breakpointLg = 1024

  const ballOptions = {
    restitution: 0.4, // bounciness
    frictionAir: 0.01, // matter default: 0.01
  }

  // create an Matter engine - this takes care of all physics calculations
  const engine = Matter.Engine.create()
  // create a renderer to render the engine's content to the screen
  const render = createRender()
  const runner = Matter.Runner.create()
  let balls

  init()

  function init() {
    createWorld()
    initMouseInteraction()
    Matter.Render.run(render)
    Matter.Runner.run(runner, engine)
  }

  function createRender() {
    return Matter.Render.create({
      element: containerElm,
      engine,
      options: {
        width: elmWidth,
        height: elmHeight,
        wireframes: false,
        background: 'transparent',
      }
    })
  }

  function createWorld() {
    const { wallLeft, wallRight, curvedGround } = createBoundaries()
    balls = createBalls()
    // add all of the bodies to the engine's world
    Matter.Composite.add(engine.world, [wallLeft, wallRight, ...curvedGround, ...balls])
  }

  // create invisible walls and ground that confine the balls
  function createBoundaries() {
    const elmCenterY = elmHeight / 2
    const wallThickness = 50
    const halfThickness = wallThickness / 2

    // when creating a body, the x and y coordinates are the center of the object
    const boundaryOptions = {
      isStatic: true,
      render: {
        fillStyle: 'transparent'
      }
    }
    const wallLeft = Matter.Bodies.rectangle(0 - halfThickness, elmCenterY, wallThickness, elmHeight, boundaryOptions)
    const wallRight = Matter.Bodies.rectangle(elmWidth + halfThickness, elmCenterY, wallThickness, elmHeight, boundaryOptions)

    const curvedGround = createCurvedGround()

    return {
      wallLeft,
      wallRight,
      curvedGround,
    }
  }

  /*
  Matter.js does not play nicely with svg shapes: it uses vertices to describe shapes instead. It has a function to create vertices from an svg shape (https://stackoverflow.com/questions/65253422/how-to-use-an-svg-image-in-matter-js), but you do need to import an additional library.
  On top of that, the curved ground is a concave shape, that has to be converted to multiple convex shapes with the poly-decomp library. When I did all that, the balls bounced like crazy.
  This function now creates a series of bars that form the curve
  */
  function createCurvedGround() {
    const partsCount = 40
    const totalAngle = 0.48 * Math.PI
    const startAngle = 0.5 * Math.PI - 0.5 * totalAngle

    const stepAngle = totalAngle / partsCount
    const partWidth = elmWidth / partsCount
    const minHeight = 40
    const isViewportLg = elmWidth >= breakpointLg
    const amplitude = isViewportLg ? 350 : 150
    const baseY = elmHeight - 50 // value found by moving up and down until it was right

    const partOptions = {
      isStatic: true,
      render: {
        fillStyle: 'transparent'
      }
    }
    const parts = []

    for (let i = 0; i < partsCount; i++) {
      const partHeight = minHeight + amplitude * (1 - Math.sin(startAngle + i * stepAngle))
      const x = i * partWidth
      const y = baseY - (partHeight / 2)
      parts.push(Matter.Bodies.rectangle(x, y, partWidth, partHeight, partOptions))
    }
    return parts
  }

  function getRandomStartingPosition(i) {
    // limit zone where balls can fall to make it look more like herbs
    const random = randomNumberGenerator()
    const dropzoneWidth = Math.min(300, elmWidth / 2)
    const dropZoneStartX = (elmWidth - dropzoneWidth) / 2
    const xRel = Math.round(dropzoneWidth * random()) // x relative to dropzone
    const avgYDistance = 1.5 * ballDiameter
    const y = -1 * ((i + 1) * avgYDistance + Math.round(avgYDistance * random()))
    const x = dropZoneStartX + xRel + ballR

    return { x, y }
  }


  function createBalls() {
    const ballCount = 30
    const balls = []
    const imageSize = 268
    const scale = ballDiameter / imageSize
    const random = randomNumberGenerator()

    for (let i = 0; i < ballCount; i++) {
      const { x, y } = getRandomStartingPosition(i)
      const image = images[i % images.length]
      const options = {
        ...ballOptions,
        render: {
          sprite: {
            texture: image,
            xScale: scale,
            yScale: scale,
          },
        },
      }
      const ball = Matter.Bodies.circle(x, y, ballR, options)
      const angularVelocity = 0.3 * random() - 0.15
      Matter.Body.setAngularVelocity(ball, angularVelocity)
      balls.push(ball)
    }
    return balls
  }

  function destroy() {
    // https://www.fabiofranchino.com/log/matter-js-cleanup-and-destroy-instances/
    // https://github.com/liabru/matter-js/issues/564
    Matter.Render.stop(render)
    Matter.Composite.clear(engine.world, false)
    Matter.Engine.clear(engine)
    render.canvas.remove()
    render.canvas = null
    render.context = null
    render.textures = {}
  }

  function initMouseInteraction() {
    const pointerType = getComputedStyle(containerElm).getPropertyValue('--pointer')
    if (pointerType === 'coarse') {
      // don't add mouse interaction on touch devices - it would prevent you from scrolling (you would interact with balls instead)
      return
    }
    const mouse = Matter.Mouse.create(render.canvas)
    const mouseConstraint = Matter.MouseConstraint.create(engine, {
      mouse,
      constraint: {
        render: {
          visible: false,
        }
      }
    })
    // adding mouse prevents page scrolling; make that work again
    // https://github.com/liabru/matter-js/issues/929
    mouseConstraint.mouse.element.removeEventListener('mousewheel', mouseConstraint.mouse.mousewheel)
    mouseConstraint.mouse.element.removeEventListener('DOMMouseScroll', mouseConstraint.mouse.mousewheel)

    const useSlicing = false
    if (useSlicing) {
      const mouseShape = Matter.Bodies.circle(100, 100, ballR, {
        render: {
          fillStyle: 'transparent',
        }
      })
      Matter.Composite.add(engine.world, mouseShape)

      Matter.Events.on(engine, 'afterUpdate', () => {
        if (!mouse.position.x) {
          return
        }
        mouseShape.position.x = mouse.position.x
        mouseShape.position.y = mouse.position.y
      })
    } else {
      // use drag and drop
      Matter.Composite.add(engine.world, [mouseConstraint])
      Matter.Events.on(mouseConstraint, 'startdrag', handleTracking)
      Matter.Events.on(engine, 'afterUpdate', () => { checkHover(mouse) })
    }
  }

  function pause() {
    runner.enabled = false
  }

  function resume() {
    runner.enabled = true
  }

  function overlapsBall(mouseX, mouseY, ball) {
    const { x: ballX, y: ballY } = ball.position
    const dY = Math.abs(mouseY - ballY)
    const dX = Math.abs(mouseX - ballX)
    return (
      dY < ballR &&
      dX < ballR &&
      dX * dX + dY * dY < ballR * ballR
    )
  }

  function setHandCursor(showPointer) {
    containerElm.style.cursor = showPointer ? 'pointer' : ''
  }

  function checkHover(mouse) {
    const random = randomNumberGenerator()
    if (random() < 0.1) {
      // checkHover is called by eventListener tied to requestAnimationFrame
      // only check once every 10 cycles to reduce load
      const { x, y } = mouse.position
      const ballCount = balls.length
      let overlapFound = false
      for (let i = 0; i < ballCount; i++) {
        const ball = balls[i]
        if (overlapsBall(x, y, ball)) {
          overlapFound = true
          break
        }
      }
      setHandCursor(overlapFound)
    }
  }

  function handleTracking() {
    trackInteraction({
      label: 'payoff-balls',
      type: 'click',
      action: 'dragged'
    })
  }

  return {
    destroy,
    pause,
    resume,
  }
}

export {
  initBallsAnimation
}
